import React from "react";
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

const ProductContent = ({images}) => {
    
	const Content = {
		starch : images.allFile.nodes[1].childImageSharp.gatsbyImageData,
		sorbitol : images.allFile.nodes[0].childImageSharp.gatsbyImageData,
		flour : images.allFile.nodes[2].childImageSharp.gatsbyImageData
	}

    return (
        <>
            <div className="py-20 lg:px-12 px-4 mx-auto container ">
            <div className="flex flex-col block mb-4 md:px-20">
            <span className="text-2xl font-light text-gray-600 dark:text-white">Our</span> 
            <span className="text-4xl font-semibold text-sgreen">Products</span>
            </div>
                {/* left Contents */}
            <div className="md:flex md:px-20 items-center mb-4 justify-between w-full">
                    <div className="xl:w-1/2 md:w-1/2 w-full mx-auto">
                        <div role="heading" className="focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen">
                            High Quality Food Grade Cassava Starch
                        </div>
                        <div className="border-b-4 border-sgreen w-3/12 mt-6" />
                        <p role="contentinfo" className="focus:outline-none text-base leading-6 mt-8 text-gray-600  text-justify dark:text-white">
                        Psaltry food grade Cassava starch has many remarkable characteristics, such as high paste viscosity, high paste clarity, and high freeze-thaw stability, which are advantageous to many food industries. We produce our products in compliance with standard GMP Practices in order to produce a safe and suitable starch in conformity with ISO 22000. Our starch is used as binders in food and beverage industries, seasoning, pudding. 
                        </p>
                    </div>
                    <div className="xl:w-3/6 md:w-1/2 w-full">
                        {/* <p role="contentinfo" className=" focus:outline-none text-base leading-6 mb-8 text-gray-600 md:mt-0 mt-10">
                            “Don’t get carried away with super-short paragraphs. The secret to one-sentence paragraphs is to use them like a spice: A pinch of cumin can make a meal more savory, but I don’t want to eat a plateful of the stuff.”
                        </p> */}
                        <div className="w-full">
                            {/* <img src="./assets/images/Cass_Strch2.png" alt="Cassava Starch" className="md:w-8/12 block mx-auto sm:w-1/2 w-8/12" /> */}
                            <GatsbyImage 
                            image={Content.starch}
                            />
                            {/* <div className="flex items-center mt-8">
                                <p role="contentinfo" className="text-xl focus:outline-none font-semibold leading-5 text-gray-700">
                                    Picture description
                                </p>
                                <p role="contentinfo" className="text-xl focus:outline-none italic font-italic leading-5 pl-2 text-gray-700">
                                    Specification
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* right Contents */}
                <div className="md:flex md:flex-row-reverse md:px-20 my-4 md:mx-auto items-center justify-between w-full">
                <div className="xl:w-1/2 md:w-1/2 ">
                        <div role="heading" className="focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen">
                           Sorbitol
                        </div>
                        <div className="border-b-4 border-sgreen w-1/12 mt-6" />
                        <p role="contentinfo" className="focus:outline-none text-base leading-6 mt-8 text-gray-600 text-justify dark:text-white">
                        Psaltry’s cassava Sorbitol is a new type of sweetener created by hydrogenation, refining, and concentrating pure glucose. It is a colorless and odorless substance used to preserve moisture (humectant), add sweetness and provide texture to products as well as potentially support digestive and oral health.
                        </p>
                        {/* <div className="mt-6">
                            <button className="flex items-center md:p-3 p-1 focus:outline-none text-xl font-medium leading-5 text-gray-800 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700">
                                Discover more
                                <svg className="ml-8" width={45} height={40} viewBox="0 0 45 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.2113 33.7887C11.0161 33.9839 11.0157 34.3009 11.2157 34.4911C13.956 37.0978 17.3821 38.8765 21.0982 39.6157C24.9778 40.3874 28.9991 39.9913 32.6537 38.4776C36.3082 36.9638 39.4318 34.4004 41.6294 31.1114C43.827 27.8224 45 23.9556 45 20C45 16.0444 43.827 12.1776 41.6294 8.8886C39.4318 5.59961 36.3082 3.03616 32.6537 1.52241C28.9991 0.00865715 24.9778 -0.38741 21.0982 0.384294C17.3821 1.12346 13.956 2.90221 11.2157 5.50886C11.0157 5.69911 11.0161 6.01609 11.2113 6.21127C11.4065 6.40645 11.7225 6.4059 11.9227 6.21592C14.523 3.74892 17.7711 2.06527 21.2932 1.36468C24.9789 0.631541 28.7993 1.00781 32.2711 2.44591C35.743 3.88401 38.7105 6.31934 40.7983 9.44394C42.8861 12.5685 44.0004 16.2421 44.0004 20C44.0004 23.7579 42.8861 27.4315 40.7983 30.5561C38.7105 33.6807 35.743 36.116 32.2711 37.5541C28.7993 38.9922 24.9789 39.3685 21.2932 38.6353C17.7711 37.9347 14.523 36.2511 11.9227 33.7841C11.7225 33.5941 11.4065 33.5935 11.2113 33.7887Z"
                                        fill="#1F2937"
                                    />
                                    <path d="M1 20H29" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M21 28L29 20" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M21 12L29 20" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        </div> */}
                    </div>  
                    <div className="xl:w-3/6 md:w-1/2 w-full">
                        {/* <p role="contentinfo" className=" focus:outline-none text-base leading-6 mb-8 text-gray-600 md:mt-0 mt-10">
                            “Don’t get carried away with super-short paragraphs. The secret to one-sentence paragraphs is to use them like a spice: A pinch of cumin can make a meal more savory, but I don’t want to eat a plateful of the stuff.”
                        </p> */}
                        <div className="w-11/12">
                            <GatsbyImage 
                            image={Content.sorbitol}
                            />
                            {/* <div className="flex items-center mt-8">
                                <p role="contentinfo" className="text-xl focus:outline-none font-semibold leading-5 text-gray-700">
                                    Picture description
                                </p>
                                <p role="contentinfo" className="text-xl focus:outline-none italic font-italic leading-5 pl-2 text-gray-700">
                                    Specification
                                </p>
                            </div> */}
                        </div>
                    </div>                 
                </div>
                <div className="md:flex md:mx-auto my-4 mt-6 md:px-10 sm:px-10 px-0 items-center justify-between w-full">
                    <div className="xl:w-1/2 md:w-1/2 w-full">
                        <div role="heading" className="focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen">
                            High Quality Food Grade Cassava Flour
                        </div>
                        <div className="border-b-4 border-sgreen w-3/12 mt-6" />
                        <p role="contentinfo" className="focus:outline-none text-base leading-6 mt-8 text-gray-600 text-justify dark:text-white">
                        We produce our HQCF with conscious adherence to Good Manufacturing Practice (GMP) to obtain the desired quality of Cassava Flour needed by the food and beverage industries. 	It is gluten-free, grain-free, and allergy free, which makes it a healthy alternative to wheat flour for baking and cooking.
                        </p>
                        <p role="contentinfo" className="focus:outline-none text-base leading-6 mt-8 text-gray-600 text-justify dark:text-white">
                        Psaltry HQCF has a wide range of use in the food industry as a vital ingredient in all sorts of baked foods, tortillas, pancakes, and gluten-free pasta and pizza. Food manufacturers also use it as a thickener for ice cream and sauces. 
                        Our gluten-free high-quality cassava flour is a cost-effective alternative to wheat to make bread. Moreover, studies have revealed that 10 to 20% of high-quality cassava flour to replace wheat flour will not alter the taste and quality of the baked food.
                        </p>
                    </div>
                    <div className="xl:w-3/6 md:w-1/2 w-full">
                        {/* <p role="contentinfo" className=" focus:outline-none text-base leading-6 mb-8 text-gray-600 md:mt-0 mt-10">
                            “Don’t get carried away with super-short paragraphs. The secret to one-sentence paragraphs is to use them like a spice: A pinch of cumin can make a meal more savory, but I don’t want to eat a plateful of the stuff.”
                        </p> */}
                        <div className="w-11/12">
                            <GatsbyImage 
                            image={Content.flour}
                            />
                            {/* <div className="flex items-center mt-8">
                                <p role="contentinfo" className="text-xl focus:outline-none font-semibold leading-5 text-gray-700">
                                    Picture description
                                </p>
                                <p role="contentinfo" className="text-xl focus:outline-none italic font-italic leading-5 pl-2 text-gray-700">
                                    Specification
                                </p>
                            </div> */}
                        </div>
                        <div className="mt-6">
                            
                           <button className="flex items-center shadow-sgreen shadow-lg shadow bg-sgreen md:p-4 p-4 focus:outline-none text-md font-medium text-white rounded-full focus:ring-2 focus:ring-offset-2 focus:ring-gray-700">
                           <Link to="/product">
                                View All Products
                            </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductContent;
