import React from "react";

export default function index() {
  return (
    <>
    <div className="dark:bg-gray-900 feature-bg relative overflow-x-hidden">
            <div className="block z-20 overflow-x-hidden">
                        {/* <img className="absolute bg-cover bg-center w-full h-full inset-0" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/Hero%2008.png" alt /> */}
                        <div className="absolute opacity-70 bg-cover bg-center w-full h-full bg-white" />
                    </div>
      <section className="relative mx-auto py-32 z-30 md:px-20 lg:px-6 sm:px-4 overflow-x-hidden dark:bg-gray-600" >
        <div className="flex flex-col">
          <div className="lg:text-4xl md:text-4xl text-4xl font-black leading-8 text-gray-800 dark:text-white px-6">
            <h4 className="font-light">Our Core</h4>
            <h1 className="text-sgreen">Values</h1>
          </div>
          <div className="pt-14 pb-18 grid lg:grid-cols-4 md:grid-cols-2 justify-center sm:w-full xs:w-full items-center xl:gap-y-16 gap-y-20 gap-x-16 lg:gap-x-20 xl:gap-x-0 lg:px-10 xl:px-0">
            <div className="cursor-pointer  py-6 xl:px-4 rounded xl:w-96 sm:w-full xs:w-full md:w-40 w-full flex justify-center items-center flex-col">
              <div className="mb-6">
              <img height={55} width={55} src="assets/images/online_support.png" alt="online_support" />
              </div>
              <div className="text-gray-800 dark:text-white text-sgreen text-2xl font-semibold text-center">
                <h2>Superior Customer Service</h2>
              </div>
              <div className="text-gray-600 dark:text-gray-300 mt-2 text-lg text-left px-6">
                <p>We promote our superior customer service, including response time to inquiries. </p>
              <div className="border-b-4 border-sgreen w-2/12 sm:my-2" />
              </div>
            </div>
            <div className="cursor-pointer py-6 xl:px-4 rounded xl:w-96 sm:w-full xs:w-full md:w-40 w-full flex justify-center items-center flex-col">
              <div className="mb-6">
              <img height={55} width={55} src="assets/images/Discount.png" alt="discount" />
              </div>
              <div className="text-gray-800 dark:text-white text-sgreen text-2xl font-semibold text-center">
                <h2>Lower Prices</h2>
              </div>
              <div className="text-gray-600 dark:text-gray-300 mt-2 text-lg text-left px-6">
                <p>We offer lower prices and our products are top notch.</p>
                <div className="border-b-4 border-sgreen w-2/12 sm:my-2" />
              </div>
            </div>
            <div className="cursor-pointer  py-6 xl:px-4 rounded xl:w-96 xs:w-full sm:w-full md:w-40 w-full flex justify-center items-center flex-col">
              <div className="mb-6">
              <img height={55} width={55} src="assets/images/Tidal.png" alt="business_model" />
              </div>
              <div className="text-gray-800 dark:text-white text-sgreen text-2xl font-semibold text-center">
                <h2>Unique Business Model</h2>
              </div>
              <div className="text-gray-600 dark:text-gray-300 mt-2 text-md text-left px-6">
                <p>We have an inclusive model where small holder farmers are at the center stage</p>
                <div className="border-b-4 border-sgreen w-2/12 sm:my-2" />
              </div>
            </div>
            <div className="cursor-pointer  py-6 xl:px-4 rounded xl:w-96 xs:w-full sm:w-full md:w-40 w-full flex justify-center items-center flex-col">
              <div className="mb-6">
              <img height={55} width={55} src="assets/images/medal_place.png" />
              </div>
              <div className="text-gray-800 dark:text-white text-sgreen text-2xl font-semibold text-center">
                <h2>Business Branding</h2>
              </div>
              <div className="text-gray-600 dark:text-gray-300 mt-2 text-lg text-left px-6">
                <p>Our business branding is focused on showing potential customers how we stand out from our competitors</p>
                <div className="border-b-4 border-sgreen w-2/12 sm:my-2" />
              </div>
            </div>
            {/* <div className="cursor-pointer  py-6 xl:px-4 rounded xl:w-96 w-60 flex justify-center items-center flex-col">
              <div className="mb-6">
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.66658 1.33331H2.66659C2.31296 1.33331 1.97382 1.47379 1.72378 1.72384C1.47373 1.97388 1.33325 2.31302 1.33325 2.66665V29.3333C1.33325 29.6869 1.47373 30.0261 1.72378 30.2761C1.97382 30.5262 2.31296 30.6666 2.66659 30.6666H6.66658V1.33331Z" fill="#C7D2FE" />
                  <path
                    d="M9.33325 30.6666H22.6666V1.33331H9.33325V30.6666ZM15.9999 25.3333C15.6463 25.3333 15.3072 25.1928 15.0571 24.9428C14.8071 24.6927 14.6666 24.3536 14.6666 24C14.6666 23.6464 14.8071 23.3072 15.0571 23.0572C15.3072 22.8071 15.6463 22.6666 15.9999 22.6666C16.3535 22.6666 16.6927 22.8071 16.9427 23.0572C17.1928 23.3072 17.3333 23.6464 17.3333 24C17.3333 24.3536 17.1928 24.6927 16.9427 24.9428C16.6927 25.1928 16.3535 25.3333 15.9999 25.3333ZM15.9999 6.66665C16.3535 6.66665 16.6927 6.80712 16.9427 7.05717C17.1928 7.30722 17.3333 7.64636 17.3333 7.99998C17.3333 8.3536 17.1928 8.69274 16.9427 8.94279C16.6927 9.19284 16.3535 9.33331 15.9999 9.33331C15.6463 9.33331 15.3072 9.19284 15.0571 8.94279C14.8071 8.69274 14.6666 8.3536 14.6666 7.99998C14.6666 7.64636 14.8071 7.30722 15.0571 7.05717C15.3072 6.80712 15.6463 6.66665 15.9999 6.66665ZM15.9999 14.6666C16.3535 14.6666 16.6927 14.8071 16.9427 15.0572C17.1928 15.3072 17.3333 15.6464 17.3333 16C17.3333 16.3536 17.1928 16.6927 16.9427 16.9428C16.6927 17.1928 16.3535 17.3333 15.9999 17.3333C15.6463 17.3333 15.3072 17.1928 15.0571 16.9428C14.8071 16.6927 14.6666 16.3536 14.6666 16C14.6666 15.6464 14.8071 15.3072 15.0571 15.0572C15.3072 14.8071 15.6463 14.6666 15.9999 14.6666Z"
                    fill="#818CF8"
                  />
                  <path d="M29.3333 1.33331H25.3333V30.6666H29.3333C29.6869 30.6666 30.026 30.5262 30.2761 30.2761C30.5261 30.0261 30.6666 29.6869 30.6666 29.3333V2.66665C30.6666 2.31302 30.5261 1.97388 30.2761 1.72384C30.026 1.47379 29.6869 1.33331 29.3333 1.33331Z" fill="#6366F1" />
                </svg>
              </div>
              <div className="text-gray-800 dark:text-white text-2xl font-semibold text-center">
                <h2>Better components</h2>
              </div>
              <div className="text-gray-600 dark:text-gray-300 mt-2 text-lg text-center">
                <p>Component-based design makes front-end web development faster.</p>
              </div>
            </div> */}
            {/* <div className="cursor-pointer  py-6 xl:px-4 rounded xl:w-96 w-60 flex justify-center items-center flex-col">
              <div className="mb-6">
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.2079 0.175992L0.781281 6.91599C0.68372 6.96073 0.601072 7.03256 0.543175 7.12294C0.485278 7.21331 0.454572 7.31842 0.454712 7.42575C0.454853 7.53308 0.485833 7.63811 0.543966 7.72833C0.6021 7.81856 0.684936 7.89017 0.782614 7.93466L15.3226 14.6253C15.7519 14.8227 16.2479 14.8227 16.6773 14.6253L31.2173 7.93466C31.315 7.8903 31.3979 7.81879 31.4562 7.72865C31.5145 7.6385 31.5456 7.53351 31.5459 7.42618C31.5461 7.31885 31.5156 7.2137 31.4578 7.12325C31.4 7.0328 31.3175 6.96086 31.2199 6.91599L16.792 0.175992C16.544 0.0601174 16.2736 6.10352e-05 15.9999 6.10352e-05C15.7263 6.10352e-05 15.4559 0.0601174 15.2079 0.175992Z"
                    fill="#818CF8"
                  />
                  <path
                    d="M31.22 15.568L28.24 14.176C28.0266 14.0763 27.794 14.0243 27.5584 14.0236C27.3229 14.0229 27.09 14.0735 26.876 14.172L16.6773 18.8653C16.4649 18.963 16.2338 19.0136 16 19.0136C15.7662 19.0136 15.5351 18.963 15.3227 18.8653L5.12267 14.172C4.90885 14.0737 4.67621 14.0232 4.4409 14.0239C4.20559 14.0246 3.97324 14.0765 3.76001 14.176L0.780006 15.568C0.682503 15.6128 0.599949 15.6848 0.54217 15.7752C0.48439 15.8657 0.453823 15.9708 0.454103 16.0782C0.454384 16.1855 0.485502 16.2905 0.543754 16.3806C0.602006 16.4708 0.684937 16.5423 0.782674 16.5866L15.3227 23.276C15.535 23.3742 15.7661 23.4251 16 23.4251C16.2339 23.4251 16.465 23.3742 16.6773 23.276L31.2173 16.5866C31.3151 16.5423 31.398 16.4708 31.4563 16.3806C31.5145 16.2905 31.5456 16.1855 31.5459 16.0782C31.5462 15.9708 31.5156 15.8657 31.4578 15.7752C31.4001 15.6848 31.3175 15.6128 31.22 15.568Z"
                    fill="#C7D2FE"
                  />
                  <path
                    d="M31.22 24.144L28.24 22.752C28.0266 22.6522 27.794 22.6002 27.5585 22.5995C27.3229 22.5989 27.09 22.6495 26.876 22.748L16.6774 27.44C16.4649 27.5376 16.2338 27.5882 16 27.5882C15.7662 27.5882 15.5351 27.5376 15.3227 27.44L5.12269 22.7466C4.90887 22.6484 4.67623 22.5978 4.44091 22.5985C4.2056 22.5992 3.97326 22.6511 3.76002 22.7506L0.780019 24.144C0.682891 24.189 0.600708 24.2609 0.543205 24.3512C0.485701 24.4415 0.455288 24.5464 0.455568 24.6535C0.455849 24.7605 0.486813 24.8653 0.544789 24.9553C0.602765 25.0453 0.685324 25.1168 0.782686 25.1613L15.3227 31.852C15.752 32.0493 16.248 32.0493 16.6774 31.852L31.2174 25.1613C31.3147 25.1168 31.3973 25.0453 31.4553 24.9553C31.5132 24.8653 31.5442 24.7605 31.5445 24.6535C31.5448 24.5464 31.5143 24.4415 31.4568 24.3512C31.3993 24.2609 31.3172 24.189 31.22 24.144Z"
                    fill="#6366F1"
                  />
                </svg>
              </div>
              <div className="text-gray-800 dark:text-white text-2xl font-semibold text-center">
                <h2>Well organised</h2>
              </div>
              <div className="text-gray-600 dark:text-gray-300 mt-2 text-lg text-center">
                <p>No more running around looking for things.When you write things and plan.</p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
     <style>
     {`
     .feature-bg{
       background-image: url('assets/images/feature_bg.jpg');
       background-repeat: no-repeat;
        background-size: cover;
     }
     `}
   </style>
   </>
  );
}
