import React from "react";
import { Link } from 'gatsby';

const Blog4 = () => {
    return (
        <div className="2xl:mx-auto 2xl:container 2xl:px-20 xl:px-12 sm:px-6 px-10 py-16 bg-gray-50 dark:bg-gray-300">
            <h1 className="lg:text-4xl text-3xl font-semibold leading-9 text-gray-800">Our News Feed</h1>
            <p className="md:w-1/2 text-base leading-normal mt-4 sm:pr-10 text-gray-600">Official updates and blog news from Psaltry International Company Limited.</p>
            <div className="md:flex items-start justify-between mt-12">
                <div className="md:w-1/2 lg:w-full">
                    <div>
                        <div className="relative">
                            <img src="assets/images/brochure.png" alt="sorbitol_launch" />
                            <div className="bg-white absolute top-0 left-0">
                                <p className="text-base leading-4 py-3 px-5 text-gray-800">Event</p>
                            </div>
                        </div>
                        {/* <p className="text-base font-light leading-4 text-gray-800 mt-6">Michael Jackson</p> */}
                        <h1 className="text-2xl font-semibold leading-7 sm:pr-20 mt-2 text-gray-800">Sorbitol Factory Launch</h1>
                        <p className="text-base leading-normal mt-4 sm:pr-20 md:pr-10 text-gray-900">
                        Commissioning of Sorbitol factory at Ado Awaye Iseyin Local Government Oyo State on the 4th of August 2022 by His Exellency Engr. Seyi Makinde the Executive Governor of Oyo State, Nigeria. The Sorbitol project
                        </p>
                        <button className="flex items-center justify-between w-64 mt-6 bg-sgreen p-4 hover:bg-gray-700">
                        <Link to="/coming-soon">
                            <p className="text-base font-medium leading-4 text-white">Read more</p>
                        </Link>
                        </button>
                    </div>
                </div>
                {/* Another Grid */}
                <div className="md:w-1/2 md:ml-6 md:mt-0 mt-9 lg:w-full">
                    <div>
                        <div className="relative">
                            <img src="assets/images/story.jpg" alt="stairs" />
                            <div className="bg-white absolute top-0 left-0">
                                <p className="text-base leading-4 py-3 px-5 text-gray-800">Brand</p>
                            </div>
                        </div>
                        {/* <p className="text-base font-light leading-4 text-gray-800 mt-6">James McFerson</p> */}
                        <h1 className="text-2xl font-semibold leading-7 sm:pr-20 mt-2 text-gray-800">The Psaltry Story</h1>
                        <p className="text-base leading-normal mt-4 sm:pr-20 md:pr-10 text-gray-600">Psaltry is an agro-processing company that refines cassava into high-quality food-grade starch for consumer products.
                        </p>
                        <p className="text-base leading-normal mt-4 sm:pr-20 md:pr-10 text-gray-600">The company's Sorbitol launch is the tip of an iceberg as we are committed to our mission of excelling cassava value-chain in Africa through
                        </p>
                        
                        <button className="flex items-center justify-between w-64 mt-6 bg-sgreen p-4 hover:bg-gray-700">
                        <Link to="/coming-soon">
                            <p className="text-base font-medium leading-4 text-white">Read more</p>
                            </Link>
                        </button>
                    </div>                   
                </div>
            </div>
        </div>
    );
};

export default Blog4;
