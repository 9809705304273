import React from 'react'


const Events = () => {
    const link = "https://youtu.be/0TpvoS6o46U";
    
    return ( 
        <>
        <div className="2xl:mx-auto 2xl:container flex justify-center bg-gray-600">
            <div className="2xl:px-20 px-6 py-12 w-full lg:w-4/5">
            <div className="flex flex-col text-3xl p-6 text-white">
            <span class="font-bold">Events</span>
            </div>
            {/* <img src='assets/images/event_img.png' className="block mx-auto" alt="commissioning_banner" /> */}
            <div className="sm:block hidden w-fit h-full mx-auto">
            <iframe 
             src="https://www.youtube.com/embed/kl6ZQ9GSAj4" 
             width="560" height="315" 
             title="Sorbitol Factory Launch"
             frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
            </div>

            <div className="sm:hidden block w-fit h-full mx-auto">
            <iframe 
             src="https://www.youtube.com/embed/kl6ZQ9GSAj4" 
             width="350" height="200" 
             title="Sorbitol Factory Launch"
             frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
            </div>
            </div>
        </div>
        
        </>
    )
}

export default Events