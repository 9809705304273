import React from 'react'
import Header from '../general/navbar'
import Hero from './hero'
import Feature from './feature'
import RawMaterial  from '../addon/RawMaterial'
import Product from '../product/productContent' 
import Caro from './csr'
import Blog from './blog'
import Events from './events'
import {TopBar, BottomBar} from '../general/extremeBars'
import { SiteMeta } from '../general/seo'
import '../../styles/global.css'
import { graphql, useStaticQuery } from 'gatsby'

// all components here in the index file and use gatsby node to
// create the routes/pages from each component folders accordingly; without stress
// Add SEO using react helmet from each individual component indexes


const HomePage = () => {
	const images = useStaticQuery(
		graphql`
		query{
			allFile(filter: {relativeDirectory: {eq: "miscImages"}}) {
			  nodes {
				childImageSharp {
				  gatsbyImageData
				}
			  }
			}
		  }
		`
	);
	
	return(
		<>
		<div className="dark:bg-gray-800">
		<SiteMeta />
		<TopBar />
		<Header />
		<Hero image={images.allFile.nodes[3]} />
		<Feature />
		{/* <RawMaterial /> */}
		<Product images={images} />
		<Blog />
		<Events />
		{/* <Caro /> */}
		<BottomBar />
		<style>
			*{`
				overflow-x: hidden;
			
			`}
		</style>
		</div>
		</>
		)
}


export default HomePage;