import React, { useState } from "react";
import {Link} from 'gatsby'
// import Flour1 from '../../assets/images/Cass_Fl1.png';
// import Starch from '../../assets/images/Cass_Strch2.png';
import { motion } from 'framer-motion'
import { GatsbyImage } from "gatsby-plugin-image";

// define your variants in const

const easeIn = {
    hidden: {opacity: 1},
    visible: {
        opacity: 1,
        transition: {
            delay: 1,
            staggerChildren: 0.1
        }
    }
}

const jumpHero = {
    hidden: { opacity: 0},
    show: {
        opacity: 1,
        transition: {
            delayChildren: 1
          }
    }
}

const letter = {
    hidden: { opacity: 0, y:50 },
    visible: {
        opacity: 1,
        y:0,
        duration: 3,
        delay: 1,
    }
}

const line1 = "Across the Globe...";

// const Floater = keyframes(`
// 0% { transform: translateY(-10px) }
// 50% { transform: translateY(15px) translateX(15px) }
// 100% { transform: translateY(-10px) }
// )`

function IndexPage({image}) {
    // const [menu, setMenu] = useState(false);
    return (
        <>
            <div className="bannerlee relative w-full h-full pb-6 pt-4">
                <div className="block">
                        {/* <img className="absolute bg-cover bg-center w-full h-full inset-0" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/Hero%2008.png" alt /> */}
                        <div className="absolute opacity-60 bg-cover bg-center w-full h-full inset-0 bg-gray-800" />
                    </div>
                <div className="relative md:px-20 container mx-auto md:flex items-center gap-8">
                    <motion.div className="text-color w-full md:w-2/3 pt-16 lg:pt-32 xl:pt-12"
                    initial="hidden"
                    animate="visible"
                    variants={letter}
                    >
                        <h4 className="text-2xl md:text-2xl lg:text-2xl text-white"> Africa's Largest Cassava Supply Chain</h4>
                        <h1 className="text-4xl md:text-4xl lg:text-6xl w-11/12 lg:w-11/12 xl:w-full xl:text-6xl text-white font-extrabold f-f-l">Watch out for this Space!</h1>
                        <div className="f-f-r text-4xl lg:text-4xl pb-20 sm:pb-0 pt-10 text-white xl:pt-6">
                           
                            <motion.div initial="hidden" animate="visible" className="text-lg" variants={easeIn}>We are working on improving your experience on our website</motion.div>
                        </div>
                        {/* <div className="lg:flex md:w-2/4">
                            <button className="md:block hover:opacity-90 text-base w-8/12 xl:text-base xl:w-6/12 mt-4 xl:mt-8 f-f-r py-4 rounded-full  bg-sgreen text-white font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                            <Link to="/about-us">
                                Learn More
                            </Link>
                                </button>
                        </div> */}
                    </motion.div>
                    {/* <img className="w-full mt-8 md:mt-0 object-fill md:w-2/3 md:-ml-4 lg:-ml-4 xl:ml-0" src={{Flour1}} alt="sample page" style={{border: "1px solid grey"}} role="img" /> */}
                    <div
                    className="md:mt-0 relative md:w-2/3 md:-ml-4 lg:-ml-4 xl:ml-0">
                    <motion.div className="h-3/4 ml-6 w-3/4 pweeshure" initial="hidden"
                    animate="show"
                    variant={jumpHero}>
                        <GatsbyImage 
                        image={image.childImageSharp.gatsbyImageData}
                        />
                    </motion.div>
                    </div>                    
                    {/* <button className="md:hidden hover:opacity-90 text-base w-full xl:text-base xl:w-6/12 mt-4 xl:mt-8 f-f-r py-4  bg-indigo-700 text-white font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-lg">Start building now</button>
                    <button className="md:hidden hover:opacity-90 text-base w-full xl:text-base xl:w-4/12 xl:ml-2 mt-4 xl:mt-8 f-f-r py-4  bg-indigo-200 text-indigo-600 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-lg">Try it out</button> */}
                </div>
            </div>


            <style>{`
        .top-100 {
            animation: slideDown .5s ease-in-out;
        }

        .bannerlee{
            background-image: url('assets/images/banner.png');
            background-repeat: no-repeat;
            background-size: cover;
        }
        
        .pweeshure{
        animation: roller 4s ease infinite;
        }

        @keyframes slideDown {
            0% {
                top: -50%;
            }

            100% {
                top: 0;
            }
        }

        @keyframes roller {
        0% { transform: translateY(-10px) }
        50% { transform: translateY(15px) translateX(15px) }
        100% { transform: translateY(-10px) }
        }

        * {
            outline: none !important;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-tap-highlight-color: transparent;
        } `}</style>
        </>
    );
}

export default IndexPage;
