import React from 'react';
import HomePage from '../components/home/index'



const IndexPage = () => {
	return(
		<>
		<HomePage />
		</>
		)
}


export default IndexPage;